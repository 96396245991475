@import "jit-refresh.css"; /* triggers frontend rebuilds */

@tailwind base;
@tailwind components;
@tailwind utilities;

.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.button {
  @apply inline-flex items-center gap-2 justify-center rounded-md py-2 px-3 text-sm outline-offset-2 transition active:transition-none no-underline;
}

.button.button-primary {
  @apply bg-zinc-800 font-semibold text-zinc-100 hover:bg-zinc-700 active:bg-zinc-800 active:text-zinc-100/70;
}

.button.button-secondary {
  @apply bg-zinc-50 font-medium text-zinc-900 hover:bg-zinc-100 active:bg-zinc-100 active:text-zinc-900/60;
}

.button svg {
  @apply ml-1 -mr-1 h-5 w-5;
}